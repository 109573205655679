<!-- eslint-disable max-len -->
<template>
  <div class='upload-cheque-details-main-wrapper'>
    <a-form>
    <a-row style='display: unset'>
      <a-col>
        <div style='margin-top: 100px'>
          <!-- <div>
            <img src='../../../assets/panImage.png' />
          </div> -->
          <div>
            <div class='upload-cheque-details-title'>Upload Cheque</div>
            <div class='upload-cheque-details-stepper'>Hello {{ clientName }}!</div>

            <div class='upload-cheque-details-sub-title-description'>
              We will read data automatically from the uploaded cancelled
              cheque.
            </div>
            <!-- <div class='upload-cheque-details-sub-title-description'>
              You can also edit details after uploading it.
            </div> -->
            <div v-if='chequeUploaded'>
              <div
                v-if='isSameAsVerifiedBankDetails'
                class='upload-cheque-details-sub-title-description'
                style="font-size: 11px; line-height: 2; color: green;"
              >
                *Bank account number & IFSC from your uploaded cheque matches with your verified bank details.
                You can proceed with your further KYC process.
              </div>
              <div
                v-if='!isSameAsVerifiedBankDetails'
                class='upload-cheque-details-sub-title-description'
                style="font-size: 11px; line-height: 2; color: red;"
              >
                *Bank account number & IFSC from your uploaded cheque don't match with your verified bank details.
                Please upload a cancelled cheque for the bank account which was verified in the previous step.
              </div>
            </div>
            <div v-if="onChequeUpload" class='upload-cheque-upload-btn'>
                <a-upload
                  v-model:file-list='chequeFileList'
                  accept='image/png, image/jpeg, image/jpg'
                  :max-Count='1'
                  :showUploadList='false'
                  :customRequest='onUploadCheque'
                >
                  <a-button
                    style='width: 100%; height: 44px; border: 1px solid #79a89b'
                  >
                  <span style="float: left;">
                    Upload File
                  </span>
                    <cloud-upload-outlined style="font-size: 20px;float: right;" />
                  </a-button>
                </a-upload>
              </div>
            <div v-if='chequeUploaded'>
              <div class='uploaded-file-name-with-delete-btn'>
                <div>{{ uploadedFileName }}</div>
                <div @click="onClickDelete" style='cursor: pointer'><delete-outlined /></div>
              </div>
            <div style='margin-top: 20px'>
              <div style='width: 100%'>
                <a-form-item v-bind='validateInfos.name'>
                <a-input
                  class='input-field'
                  v-model:value='modelRef.name'
                  placeholder='Bank Name and Branch'
                  disabled
                >
                </a-input>
                </a-form-item>
              </div>
            </div>
            <div style='display: flex; margin-top: 20px'>
              <div style='width: 100%; margin-right: 10px'>
                <a-form-item v-bind='validateInfos.account'>
                <a-input
                  class='input-field'
                  v-model:value='modelRef.account'
                  placeholder='Account Number'
                  disabled
                >
                </a-input>
                </a-form-item>
              </div>
              <div style='width: 100%'>
                <a-form-item v-bind='validateInfos.ifsc'>
                <a-input
                  class='input-field'
                  v-model:value='modelRef.ifsc'
                  placeholder='IFSC Code'
                  disabled
                >
                </a-input>
                </a-form-item>
              </div>
            </div>

            <div class='upload-cheque-submit-btn-wrapper'>
              <a-button type='primary' @click='onClickContinue' :disabled=!isSameAsVerifiedBankDetails>Continue</a-button
              >
            </div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
    </a-form>
  </div>
</template>
<script>
// import { useRouter } from 'vue-router';
import {
  onMounted,
  ref,
  // computed,
  reactive,
} from 'vue';
import { DeleteOutlined, CloudUploadOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { Form } from 'ant-design-vue';
import services from '../../../services/apis';
import Operations from '../../../utils/operations';

const { useForm } = Form;

export default {
  components: {
    DeleteOutlined,
    CloudUploadOutlined,
  },
  setup() {
    const store = useStore();
    const clientName = ref('');
    onMounted(() => {
      window.scrollTo(0, 0);
      services
        .getStages()
        .then((response) => {
          store.dispatch('onboardingStore/updateStage', response.data.data.stage);
          clientName.value = response.data.data.prefill.name;
          store.dispatch('onboardingStore/updateClientName', clientName);
        })
        .catch(() => {
        });
    });

    const onChequeUpload = ref(true);
    const chequeUploaded = ref(false);
    const isSameAsVerifiedBankDetails = ref(false);
    const uploadedFileName = ref('');
    const s3Link = ref('');

    const modelRef = reactive({
      name: '',
      ifsc: '',
      account: '',
    });

    // const rulesRef = reactive({
    //   name: [
    //     {
    //       required: true,
    //       message: 'Please enter',
    //     },
    //   ],
    //   ifsc: [
    //     {
    //       required: true,
    //       message: 'Please enter',
    //     },
    //   ],
    //   account: [
    //     {
    //       required: true,
    //       message: 'Please enter',
    //     },
    //   ],
    // });
    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      // rulesRef,
    );

    const onUploadCheque = ({ onSuccess, file }) => {
      const data = new FormData();
      data.append('file', file);
      data.append('documentType', 'CHEQUE');
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      services
        .uploadDocument(data, config)
        .then((uploadResponse) => {
          uploadedFileName.value = uploadResponse.data.name;
          s3Link.value = uploadResponse.data.url;
          // message.success(`${uploadResponse.data.name} uploaded successfuly`, 2);
          const UploadedData = {
            image: s3Link.value,
          };
          const notificationId = Operations.showNotification('Cheque uploaded successfully!', 'Please wait while we are reading details from your uploaded cheque...');
          services
            .chequeUpload(UploadedData)
            .then((response) => {
              onSuccess(null, response);
              Operations.closeNotification(notificationId);
              const { data: { ocr, verification } = {} } = response;

              if (verification.ocr) {
                modelRef.name = ocr.name;
                modelRef.account = ocr.account;
                modelRef.ifsc = ocr.ifsc;
                isSameAsVerifiedBankDetails.value = verification.matches;
                onChequeUpload.value = false;
                chequeUploaded.value = true;
              } else {
                Operations.notificationError(
                  'Cheque verification failed!',
                  'Please upload a valid cancelled cheque in which your bank details are clearly visible.',
                  { duration: 4 },
                );
                onChequeUpload.value = true;
                chequeUploaded.value = false;
              }
            })
            .catch((e) => {
              onChequeUpload.value = true;
              chequeUploaded.value = false;
              Operations.notificationError('Cheque upload and verified failed!', e.response.data.error.message, { duration: 4 });
            });
        })
        .catch(() => {
        });
    };

    const onClickDelete = () => {
      onChequeUpload.value = true;
      chequeUploaded.value = false;
      isSameAsVerifiedBankDetails.value = false;
    };

    const onClickContinue = () => {
      validate().then(() => {
        services
          .confirmCheque()
          .then((response) => {
            store.dispatch('onboardingStore/updateStage', response.data.data.stage);
            // store.dispatch('onboardingStore/updateRouteName', '/uploaded/cheque');
            // router.push('/bank/verification');
          })
          .catch(() => {
          });
      });
    };
    return {
      onClickContinue,
      onChequeUpload,
      chequeUploaded,
      isSameAsVerifiedBankDetails,
      clientName,
      onUploadCheque,
      uploadedFileName,
      s3Link,
      validateInfos,
      resetFields,
      modelRef,
      onClickDelete,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/uploadChequeDetails.scss';
</style>
